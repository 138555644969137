import { AxiosInstance } from 'axios';

import { IMemberLocal } from './types';
import { IMembersState } from '../../../redux/members/types';

export class MemberApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getMembers(member: IMemberLocal): Promise<IMembersState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/members?page=${member.page}&search=${member.search}`,
    );
    return data;
  }
}
