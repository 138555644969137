/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { test } from './action';
import { ITestState } from './types';

export const initialState: ITestState = {
  message: '',
};

const testSlice = createGenericSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(test.rejected, () => {
      console.error('Login Failed');
    });

    builder.addCase(test.fulfilled, (state, action: any) => {
      state.message = action.payload.message;
    });
  },
});

export { test };
export default testSlice.reducer;
