import { AxiosInstance } from 'axios';

import { IParticipantLocal } from './types';
import { IParticipantsState } from '../../../redux/participants/types';

export class ParticipantApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getParticipants(
    participant: IParticipantLocal,
  ): Promise<IParticipantsState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/seminars-with-participant?page=${participant.page}`,
    );
    return data;
  }
}
