import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { POPUP_TITLE } from '../../themes/colors';

interface Props {
  title: string;
}

const Base: FC<Props> = ({ title }: Props) => {
  return (
    <Typography
      sx={{
        fontSize: '18px',
        lineHeight: '24px',
        height: '24px',
        marginLeft: '20px',
        marginTop: '10px',
        color: POPUP_TITLE,
      }}
    >
      {title}
    </Typography>
  );
};

export default Base;
