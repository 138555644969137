import { FC } from 'react';
import ConfirmButton from './ConfirmButton';
import { translate } from '../../libs/utils';
import { ORANGE } from '../../themes/colors';
import CancelButton from './CancelButton';
import Layout from './Layout';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  open: boolean;
}

const DeletePopup: FC<Props> = ({
  onConfirm,
  onCancel,
  onClose,
  open,
}: Props) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Layout
      title={translate('popup.delete.title')}
      content={translate('popup.delete.content')}
      isOpen={open}
      onClose={handleClose}
    >
      <>
        <ConfirmButton
          label={translate('popup.delete.button_yes')}
          width={'109px'}
          bgColor={ORANGE}
          onClick={handleConfirm}
        />
        <span style={{ marginLeft: '15px', marginRight: '15px' }}></span>
        <CancelButton
          label={translate('popup.delete.button_no')}
          width={'109px'}
          onClick={handleCancel}
        />
      </>
    </Layout>
  );
};

export default DeletePopup;
