/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { createNotification } from './action';
import { INotificationsState } from './types';

export const initialState: INotificationsState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: {
    title: '',
    detail: '',
    target_area: [],
    created_by: 0,
    notification_id: 0,
  },
};

const notificationSlice = createGenericSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNotification.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.success = action.payload.success;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.message = action.payload.message;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.status = action.payload.status;
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(
      createNotification.fulfilled,
      (state, action: PayloadAction<INotificationsState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
        state.message = action.payload.message;
        delete state.errors;
      },
    );
  },
});

export default notificationSlice.reducer;
