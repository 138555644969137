import { Navigate, Outlet } from 'react-router-dom';
import { TOKEN_KEYS } from '../configs';
import { getItemDataStorage } from '../libs/utils/localStorage';

function ProtectedRoute() {
  const token = getItemDataStorage(TOKEN_KEYS.ACCESS_TOKEN);
  return token ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedRoute;
