import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line no-undef
const isDev = process.env.NODE_ENV !== 'production';

export const store = configureStore({
  reducer: rootReducer,
  devTools: isDev,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
