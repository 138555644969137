/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { IParticipantsState } from './types';
import { IParticipantLocal } from '../../libs/apis/participants/types';

export const getParticipants = createAsyncThunk(
  '/admin/participants',
  async (memberData: IParticipantLocal, { rejectWithValue }) => {
    try {
      const data: IParticipantsState =
        await apiSdk.participantApis.getParticipants(memberData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
