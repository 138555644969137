import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface CommonColors {
    backgroundPrimary: string;
    backgroundSecondary: string;
    backgroundTertiary: string;
    textPrimary: string;
    textSecondary: string;
    border: string;
    borderSecondary: string;
    borderRadius: string;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1536,
    },
  },
  palette: {
    common: {
      backgroundPrimary: '#343a40',
      backgroundSecondary: '#ffffff1a',
      textPrimary: '#d0d4db',
      textSecondary: '#00000080',
      border: '#4b545c',
      borderSecondary: '#dee2e6',
      borderRadius: '0px',
    },
  },
});

export default theme;
