export const TITLE = '32px';
export const TITLE_LINE_HEIGHT = '43px';
export const TOPIC_LABEL = '14px';
export const TOPIC_INPUT_VALUE = '16px';
export const TOPIC_NOTICE_LABEL = '12px';
export const TABLE_HEADER = '14px';
export const TABLE_CELL = '12px';
export const BUTTON_LABEL = '11px';
export const BUTTON_HEIGHT = '27px';
export const BUTTON_LABEL_HEIGHT = '18px';
