import { Button as MButton, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { BLACK, POPUP_CONTENT, WHITE } from '../../themes/colors';
import {
  BUTTON_HEIGHT,
  BUTTON_LABEL,
  BUTTON_LABEL_HEIGHT,
} from '../../themes/fonts';

interface Props {
  label: string;
  width: string;
  onClick: () => void;
}

const CancelButton: React.FC<Props> = ({ label, width, onClick }: Props) => {
  const handleOnClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <MButton
      onClick={() => handleOnClick()}
      sx={{
        width: width,
        bgcolor: WHITE,
        borderColor: POPUP_CONTENT,
        height: BUTTON_HEIGHT,
      }}
      variant="outlined"
    >
      <Typography
        sx={{
          color: BLACK,
          fontSize: BUTTON_LABEL,
          lineHeight: BUTTON_LABEL_HEIGHT,
        }}
      >
        {label}
      </Typography>
    </MButton>
  );
};

export default CancelButton;
