import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import BuildIcon from '@mui/icons-material/Build';
import QuizIcon from '@mui/icons-material/Quiz';
import GroupIcon from '@mui/icons-material/Group';
import { Stack, Typography } from '@mui/material';
import { translate } from '../libs/utils';
import { BLACK } from '../themes/colors';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux';
import { useSelector } from 'react-redux';
import { logoutSuccessSelector } from '../redux/auth/selectors';
import { logout } from '../redux/auth';
import { removeItemDataStorage } from '../libs/utils/localStorage';
import { TOKEN_KEYS } from '../configs';

interface Props {
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
}

const TemporaryDrawer: React.FC<Props> = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogoutSuccess = useSelector(logoutSuccessSelector);

  const handleNavigate = (to: string) => {
    if (to === '/logout') {
      handleLogout();
      return;
    }
    navigate(to);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  React.useEffect(() => {
    if (isLogoutSuccess) {
      removeItemDataStorage(TOKEN_KEYS.ACCESS_TOKEN);
      removeItemDataStorage(TOKEN_KEYS.EXPIRES_IN);
      // eslint-disable-next-line no-undef
      window.location.href = '/login';
    }
  }, [isLogoutSuccess]);

  const menuList = [
    {
      text: translate('drawer.menu.all_member'),
      icon: <LoginIcon sx={{ fontSize: '16px' }} />,
      to: '/members',
    },
    {
      text: translate('drawer.menu.all_seminar'),
      icon: <HomeIcon sx={{ fontSize: '16px' }} />,
      to: '/seminars',
    },
    {
      text: translate('drawer.menu.check_participant'),
      icon: <BuildIcon sx={{ fontSize: '16px' }} />,
      to: '/participants',
    },
    {
      text: translate('drawer.menu.all_safety'),
      icon: <AssignmentIcon sx={{ fontSize: '16px' }} />,
      to: '/',
    },
    {
      text: translate('drawer.menu.all_questionnaire'),
      icon: <QuizIcon sx={{ fontSize: '16px' }} />,
      to: '/',
    },
    {
      text: translate('drawer.menu.detail_notification'),
      icon: <NotificationsNoneIcon sx={{ fontSize: '18px' }} />,
      to: '/notifications',
    },
    {
      text: translate('drawer.menu.role'),
      icon: <GroupIcon sx={{ fontSize: '18px' }} />,
      to: '/role',
    },
    {
      text: translate('drawer.menu.logout'),
      icon: <LogoutIcon sx={{ fontSize: '18px' }} />,
      to: '/logout',
    },
  ];

  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      <Stack height="100vh" justifyContent="space-between">
        <Box>
          <Box
            sx={{ height: '62px', paddingBottom: '8px' }}
            display="flex"
            justifyContent="center"
            alignItems="end"
          >
            <Typography sx={{ fontSize: '20px', lineHeight: '30px' }}>
              {translate('drawer.menu')}
            </Typography>
          </Box>
          <Divider />
          <List sx={{ marginBottom: '24px' }}>
            {menuList.map((menu) => (
              <ListItem key={menu.text} disablePadding>
                <ListItemButton onClick={() => handleNavigate(menu.to)}>
                  <ListItemIcon sx={{ color: BLACK, fontSize: '20px' }}>
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    sx={{ fontSize: '12px' }}
                    primary={menu.text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
        <Stack justifyContent="center" alignItems="center" marginBottom="20px">
          <Typography>ユーザ</Typography>
          <Typography>山田花子</Typography>
          <Typography>test@test.com</Typography>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <Drawer open={isOpen} onClose={() => toggleDrawer(false)}>
      {DrawerList}
    </Drawer>
  );
};

export default TemporaryDrawer;
