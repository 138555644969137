import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { translate } from '../../libs/utils';
import Table from '../../components/table/Table';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux';
import { seminarsSelector } from '../../redux/seminars/selectors';
import { getSeminars } from '../../redux/seminars/action';
import { IData, ISeminars } from '../../redux/seminars/types';
import Pagination from '../../components/table/Pagination';
import TableBody from '../../components/seminars/TableBody';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';

const headers = [
  { id: 'seminar_title', label: translate('seminar.list.table.seminar_title') },
  { id: 'organizer', label: translate('seminar.list.table.organizer') },
  { id: 'target', label: translate('seminar.list.table.target') },
  { id: 'date', label: translate('seminar.list.table.date') },
  { id: 'period', label: translate('seminar.list.table.period') },
  { id: 'status', label: translate('seminar.list.table.status') },
  { id: 'detail', label: translate('seminar.list.table.detail') },
  { id: 'create_survey', label: translate('seminar.list.table.create_survey') },
];

const AllSeminarScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [textTotal, setTextTotal] = useState<string>('');
  const [seminars, setSeminars] = useState<ISeminars[]>([]);

  // TODO: remove after integrate API
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSeminarId, setSelectedSeminarId] = useState<number>(0);

  const dispatch = useAppDispatch();
  const data: IData = useSelector(seminarsSelector);

  const handleOnChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleNavigateToDetailScreen = (seminarId: number) => {
    // TODO: dispatch api
    setSelectedSeminarId(seminarId);
  };

  const handleCreateSurvey = (seminarId: number) => {
    // TODO: dispatch api
    setSelectedSeminarId(seminarId);
  };

  useEffect(() => {
    dispatch(getSeminars({ page: currentPage }));
  }, [currentPage, dispatch]);

  useEffect(() => {
    setSeminars(data.seminars);
    setCurrentPage(data.current_page);
    setTotal(data.total);
    const textTotal = `${translate('table.total.label')} ${total}${translate('seminar.list.table.paginate.label')}`;
    setTextTotal(textTotal);
  }, [data.current_page, data.seminars, data.total, seminars, total]);

  return (
    <Layout title={translate('seminar.list.title')}>
      <Table headers={headers}>
        <TableBody
          data={seminars}
          onClickDetail={handleNavigateToDetailScreen}
          onClickCreate={handleCreateSurvey}
        ></TableBody>
      </Table>
      <Pagination
        total={calculateTotalPage(total)}
        page={currentPage}
        textTotal={textTotal}
        onChangePage={handleOnChangePage}
      />
    </Layout>
  );
};
export default AllSeminarScreen;
