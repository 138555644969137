import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { IAdminUserState } from './types';
import { IAdminUserLocal } from '../../libs/apis/admin-users/types';

export const getAdminUsers = createAsyncThunk(
  '/admin/members',
  async (adminUser: IAdminUserLocal) => {
    const data: IAdminUserState =
      await apiSdk.adminUserApis.getAdminUsers(adminUser);
    return data;
  },
);
