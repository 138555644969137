import { FC } from 'react';
import ConfirmButton from './ConfirmButton';
import { translate } from '../../libs/utils';
import { GREEN } from '../../themes/colors';
import Layout from './Layout';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
}

const EditPopup: FC<Props> = ({ onConfirm, onClose, open }: Props) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Layout
      title={translate('popup.edit.title')}
      content={translate('popup.edit.content')}
      isOpen={open}
      onClose={handleClose}
    >
      <ConfirmButton
        label={translate('popup.edit.button')}
        width={'195px'}
        bgColor={GREEN}
        onClick={handleConfirm}
      />
    </Layout>
  );
};

export default EditPopup;
