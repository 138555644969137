/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { getMembers } from './action';
import { IMembersState } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialState: IMembersState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
    members: [],
  },
};

const memberSlice = createGenericSlice({
  name: 'member',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMembers.rejected, (state, action: any) => {
      state.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.message = action.payload.message;
        state.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.status = action.payload.status;
      }
    });

    builder.addCase(
      getMembers.fulfilled,
      (state, action: PayloadAction<IMembersState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );
  },
});

export { getMembers };
export default memberSlice.reducer;
