import { RootState } from '..';

export const emailErrorSelector = (state: RootState): string[] | undefined =>
  state.auth.login.error?.email;

export const passwordErrorSelector = (state: RootState): string[] | undefined =>
  state.auth.login.error?.password;

export const messageSelector = (state: RootState): string =>
  state.auth.login.message;

export const successSelector = (state: RootState): boolean =>
  state.auth.login.success;

export const logoutSuccessSelector = (state: RootState): boolean =>
  state.auth.logout.success;
