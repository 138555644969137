import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IHeader, translate } from '../../libs/utils';
import Table from '../../components/table/Table';
import { useAppDispatch } from '../../redux';
import { useSelector } from 'react-redux';
import TableBody from '../../components/admin-users/TableBody';
import { Box } from '@mui/material';
import TableButton from '../../components/table/TableButton';
import { getAdminUsers } from '../../redux/admin-users/action';
import { adminUsersSelector } from '../../redux/admin-users/selectors';
import { IData } from '../../redux/admin-users/types';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';
import Pagination from '../../components/table/Pagination';

const headers: IHeader[] = [
  {
    id: 'dental_registration_number',
    label: translate('administrator.list.table.dental_registration_number'),
  },
  { id: 'name', label: translate('administrator.list.table.name') },
  { id: 'email', label: translate('administrator.list.table.email') },
  {
    id: 'birth_date',
    label: translate('administrator.list.table.date_of_birth'),
  },
  {
    id: 'action',
    label: translate('administrator.list.table.role_action'),
  },
];

const AdminUsersScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const adminUsersData: IData = useSelector(adminUsersSelector);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnChangeRole = (role: number, adminUserId: number) => {
    // TODO: dispatch change role api
  };

  const handleOnChangePage = (page: number) => {
    setCurrentPage(page);
    dispatch(getAdminUsers({ page: page }));
  };

  useEffect(() => {
    dispatch(getAdminUsers({ page: currentPage }));
  }, [currentPage, dispatch]);

  return (
    <Layout title={translate('administrator.list.title')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          bgcolor: 'background.paper',
          p: 2,
          borderRadius: 1,
          mb: '5px',
          mt: '-35px',
        }}
      >
        <Box>
          {/* TODO: add onClick event */}
          <TableButton label={translate('button.add')} />
        </Box>
      </Box>
      <Table headers={headers}>
        <TableBody
          data={adminUsersData.items}
          onChangeRole={handleOnChangeRole}
        />
      </Table>
      <Pagination
        total={calculateTotalPage(adminUsersData.total)}
        page={adminUsersData.current_page}
        textTotal=""
        onChangePage={handleOnChangePage}
      />
    </Layout>
  );
};
export default AdminUsersScreen;
