/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { API_BASE_URL, HTTP_STATUS, TOKEN_KEYS } from '../../configs';
import {
  getItemDataStorage,
  removeItemDataStorage,
} from '../utils/localStorage';
import { TestApi } from './test';
import { AuthApi } from './auth';
import { MemberApi } from './members';
import { SeminarApi } from './seminars';
import { ParticipantApi } from './participants';
import { AreaApi } from './areas';
import { NotificationApi } from './notifications';
import { AdminUserApi } from './admin-users';

class Api {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: API_BASE_URL,
    });

    this.instance.interceptors.request.use(
      this.onRequestFulfilled,
      this.onRequestReject,
    );

    this.instance.interceptors.response.use(
      this.onResponseFulfilled,
      this.onResponseReject,
    );
  }

  onResponseFulfilled = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  onResponseReject = async (error: AxiosError) => {
    if (error.response) {
      switch (error.response?.status) {
        case HTTP_STATUS.UNAUTHORIZED: {
          const originalRequest: any = error.config;
          if (!originalRequest._retry) {
            originalRequest._retry = true;

            const accessToken = getItemDataStorage(TOKEN_KEYS.ACCESS_TOKEN);
            if (accessToken) {
              const { data } = await this.instance.post(
                'api/v1/auth/refresh-token',
                {
                  refreshToken: getItemDataStorage(TOKEN_KEYS.REFRESH_TOKEN),
                },
              );
              if (data.access_token) {
                originalRequest.headers.authorization = `Bearer ${data.access_token}`;
              }
            }
            return this.instance(originalRequest);
          }

          removeItemDataStorage(TOKEN_KEYS.ACCESS_TOKEN);
          removeItemDataStorage(TOKEN_KEYS.REFRESH_TOKEN);
          break;
        }
        case HTTP_STATUS.FORBIDDEN: // navigate to page 403
          break;
        default:
          throw {
            statusCode: error.response.status,
            message: this.getErrorMessage(error),
          };
      }
    } else {
      throw error;
    }
  };

  onRequestFulfilled = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> => {
    const accessToken = getItemDataStorage(TOKEN_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      const bearerToken = `Bearer ${accessToken}`;
      config.headers = {
        ...(config.headers ?? {}),
        ['Authorization']: bearerToken,
      };
    }
    return config;
  };

  onRequestReject = (error: any) => {
    return Promise.reject(error);
  };

  // TODO: Get message from error's response
  getErrorMessage = (error: AxiosError) => {
    if (error.response && error.response.data) {
      if (Array.isArray(error.response.data)) {
        return error.response.data;
      }
      return error.response.data;
    }
    return 'An error has occurred, please try again';
  };
}

const { instance } = new Api();

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace apiSdk {
  // todo: Remove TestApi
  export const testApis = new TestApi(instance);
  export const authApis = new AuthApi(instance);
  export const areaApis = new AreaApi(instance);
  export const memberApis = new MemberApi(instance);
  export const notificationApis = new NotificationApi(instance);
  export const seminarApis = new SeminarApi(instance);
  export const participantApis = new ParticipantApi(instance);
  export const adminUserApis = new AdminUserApi(instance);
}
