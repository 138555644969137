/* eslint-disable no-undef */
import React from 'react';
import { translate } from '../../libs/utils';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { MAIN_COLOR, MAIN_LIGHT_COLOR } from '../../themes/colors';

const useStyles = makeStyles({
  customTextField: {
    '& .MuiInputBase-input': {
      padding: '13.5px 15px',
      width: '145px',
      lineHeight: '18px',
      fontSize: '12px',
    },
    '& input::placeholder': {
      lineHeight: '18px',
      fontSize: '12px',
    },
  },
  icon: {
    color: MAIN_COLOR,
    '&:hover': {
      color: MAIN_LIGHT_COLOR,
      cursor: 'pointer',
    },
    width: '18px !important',
    height: '18px !important',
    marginLeft: '10px',
    marginRight: '10px',
  },
});

interface Props {
  searchValue: string;
  onChange: (value: string) => void;
  onClick: () => void;
}

const SearchInput: React.FC<Props> = ({ searchValue, onChange, onClick }) => {
  const classes = useStyles();

  const handleClickButton = () => {
    onClick();
  };

  const handleOnChange = (value: string) => {
    onChange(value);
  };

  return (
    <>
      <TextField
        name="search"
        type="text"
        className={classes.customTextField}
        placeholder={translate('member.list.table.search')}
        variant="outlined"
        // eslint-disable-next-line no-undef
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange(e.currentTarget.value)
        }
        value={searchValue}
      />
      <SearchIcon className={classes.icon} onClick={handleClickButton} />
    </>
  );
};
export default SearchInput;
