/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { IAreasState } from './types';

export const getAreas = createAsyncThunk(
  '/admin/areas',
  async (_, { rejectWithValue }) => {
    try {
      const data: IAreasState = await apiSdk.areaApis.getAreas();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
