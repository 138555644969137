import { AxiosInstance } from 'axios';
import { ISeminarLocal } from './types';
import { ISeminarsState } from '../../../redux/seminars/types';

export class SeminarApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getSeminars(seminar: ISeminarLocal): Promise<ISeminarsState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/seminars?page=${seminar.page}`,
    );
    return data;
  }
}
