import './App.css';
import '@fontsource/manrope';
import { ThemeProvider } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from './languages/i18n';
import theme from './themes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TestView from './pages/test';
import Login from './pages/auth/Login';
import ProtectedRoute from './components/ProtectedRoute';
import AllSeminarScreen from './pages/seminars/list';
import MembersScreen from './pages/members/list';
import ParticipantsScreen from './pages/participants/list';
import CreateNotificationScreen from './pages/notifications/create';
import AdminUsersScreen from './pages/admin-users/list';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<TestView />} />
              <Route path="/seminars" element={<AllSeminarScreen />} />
              <Route path="/members" element={<MembersScreen />} />
              <Route path="/participants" element={<ParticipantsScreen />} />
              <Route
                path="/notifications"
                element={<CreateNotificationScreen />}
              />
              <Route path="/role" element={<AdminUsersScreen />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
