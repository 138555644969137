import { AxiosInstance } from 'axios';

import { ITest } from './types';

export class TestApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async test(): Promise<ITest> {
    const { data } = await this.axiosInstance.get('/api/test');
    return data;
  }
}
