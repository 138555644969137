import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import jp from './jp/common.json';

i18next.use(initReactI18next).init({
  lng: 'jp',
  ns: ['common'],
  defaultNS: 'common',
  resources: {
    jp: {
      common: jp,
    },
  },
  interpolation: { escapeValue: false },
});

export default i18next;
