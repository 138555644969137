import { Button as MButton, SxProps, Typography } from '@mui/material';
import React, { useCallback } from 'react';

interface Props {
  label: string;
  sx: SxProps;
  textSx: SxProps;
  onClick: () => void;
  onFocus?: () => void;
}

const Button: React.FC<Props> = ({ label, sx, textSx, onClick, onFocus }) => {
  const handleOnClick = useCallback(() => {
    onClick();
  }, [onClick]);
  return (
    <MButton
      onFocus={onFocus}
      onClick={() => handleOnClick()}
      sx={sx}
      variant="contained"
    >
      <Typography sx={textSx}>{label}</Typography>
    </MButton>
  );
};

export default Button;
