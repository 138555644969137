/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { translate } from '../../libs/utils';
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  LIGHT_PURPLE,
  MAIN_LIGHT_COLOR,
  NOTICE,
  TYPOGRAPHY,
  WHITE,
} from '../../themes/colors';
import Button from '../../components/Button';
import { IArea } from '../../redux/areas/types';
import { useSelector } from 'react-redux';
import { areasSelector } from '../../redux/areas/selectors';
import { useAppDispatch } from '../../redux';
import { getAreas } from '../../redux/areas/action';
import Chip from '../../components/Chip';
import { useNavigate } from 'react-router-dom';
import { createNotification } from '../../redux/notifications/action';
import {
  errorsSelector,
  messageSelector,
  successSelector,
} from '../../redux/notifications/selectors';
import ErrorMessage from '../../components/ErrorMessage';
import { IError } from '../../redux/notifications/types';

const useStyles = makeStyles({
  container: {
    maxWidth: '960px',
    marginLeft: '32px',
    marginRight: '32px',
  },

  labelContainer: {
    minWidth: '240px',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  textLabel: {
    color: WHITE,
    fontSize: '12px !important',
    fontWeight: 'bold !important',
  },
});

const CreateNotificationScreen: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [areas, setAreas] = useState<IArea[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<IArea[]>([]);
  const [value, setValue] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [detail, setDetail] = useState<string>('');
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [errorsMessage, setErrorsMessage] = useState<IError | undefined>({});
  const [message, setMessage] = useState<string>('');
  const dispatch = useAppDispatch();

  const messagesSelector: string = useSelector(messageSelector);
  const errorsMessageSelector: IError | undefined = useSelector(errorsSelector);
  const isSuccess: boolean | undefined = useSelector(successSelector);
  const data: IArea[] = useSelector(areasSelector);

  const handleCreateButton = () => {
    const areaIds = selectedAreas.map((area) => area.area_id);
    setIsSubmit(true);
    dispatch(
      createNotification({
        title,
        detail,
        target_area: areaIds,
      }),
    ).then(() => setIsSubmit(false));
  };

  const handleBackButton = () => navigate('/seminars');

  useEffect(() => {
    dispatch(getAreas());
  }, [dispatch]);

  useEffect(() => {
    setAreas(data);
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      setMessage(messagesSelector);
      setErrorsMessage(errorsMessageSelector);
      if (isSuccess) setOpenSnackbar(true);
    }
  }, [isSubmit, messagesSelector, errorsMessageSelector]);

  const handleSelectArea = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    const area = areas.find(
      (item) => item.area_id == parseInt(event.target.value),
    );
    if (area && !selectedAreas.find((item) => item.area_id == area.area_id)) {
      const selectedValue = selectedAreas;
      selectedValue.push(area);
      setSelectedAreas([...selectedValue]);
    }
  };

  const handleDeleteArea = (areaId: number) => {
    const selectedValue = selectedAreas;
    const area = selectedValue.find((item) => item.area_id == areaId);
    if (area) {
      const index = selectedValue.indexOf(area);
      selectedValue.splice(index, 1);
      setSelectedAreas([...selectedValue]);
    }
  };

  const handleOnBlurTitle = (title: string) => {
    setTitle(title);
  };

  const handleOnBlurDetail = (detail: string) => {
    setDetail(detail);
  };

  return (
    <Layout title={translate('notification.title')}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={3000}
        key="snackbar"
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={3}
        className={styles.container}
        sx={{ height: '65px' }}
      >
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('notification.label.title')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            sx={{ width: '100%' }}
            id="title"
            name="title"
            type="text"
            variant="standard"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleOnBlurTitle(e.target.value);
            }}
          />
        </Box>
      </Stack>
      <Stack
        flexDirection="row"
        className={styles.container}
        gap={3}
        sx={{ height: '120px' }}
      >
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('notification.label.detail')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            sx={{ width: '100%' }}
            id="detail"
            name="title"
            type="text"
            label="Label"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleOnBlurDetail(e.target.value);
            }}
          />
        </Box>
      </Stack>
      <Stack
        flexDirection="row"
        className={styles.container}
        gap={3}
        sx={{ height: '120px' }}
      >
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('notification.label.target_area')}
          </Typography>
        </Box>
        <Stack flexDirection="row" gap={2} sx={{ flexGrow: 1 }}>
          <Box sx={{ width: '200px' }}>
            <Typography
              sx={{ fontSize: '12px', color: NOTICE, fontWeight: '600' }}
            >
              {translate('notification.label.target_area.specific_area')}
            </Typography>
            <FormControl fullWidth sx={{ marginTop: '12px' }}>
              <InputLabel id="demo-simple-select-label">
                {translate('notification.label.target_area.area')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={translate('notification.label.target_area.area')}
                onChange={handleSelectArea}
              >
                {areas.map((area: IArea) => (
                  <MenuItem key={area.area_id} value={area.area_id}>
                    {area.area_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Stack
            alignContent="flex-start"
            flexWrap="wrap"
            flexDirection="row"
            gap={1}
            sx={{
              flexGrow: 1,
              backgroundColor: '#F2F2F2',
              borderRadius: '5px',
              padding: '8px 12px',
            }}
          >
            {selectedAreas.map((area) => (
              <Chip
                key={area.area_id}
                label={area.area_name}
                sx={{
                  backgroundColor: LIGHT_PURPLE,
                  width: '100px',
                }}
                textSx={{
                  color: TYPOGRAPHY,
                  fontSize: '12px',
                  fontWeight: '500',
                }}
                onClick={() => handleDeleteArea(area.area_id)}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      {errorsMessage?.title && errorsMessage.title.length > 0 && (
        <Box
          className={styles.container}
          sx={{ marginY: '20px', marginLeft: '300px' }}
        >
          <ErrorMessage message={errorsMessage.title[0]} />
        </Box>
      )}
      {errorsMessage?.detail && errorsMessage.detail.length > 0 && (
        <Box
          className={styles.container}
          sx={{ marginY: '20px', marginLeft: '300px' }}
        >
          <ErrorMessage message={errorsMessage.detail[0]} />
        </Box>
      )}
      {errorsMessage?.target_area && errorsMessage.target_area.length > 0 && (
        <Box
          className={styles.container}
          sx={{ marginY: '20px', marginLeft: '300px' }}
        >
          <ErrorMessage message={errorsMessage.target_area[0]} />
        </Box>
      )}
      <Stack
        flexDirection="row"
        justifyContent="center"
        gap={5}
        sx={{ marginTop: '25px' }}
      >
        <Button
          label={translate('notification.button.create')}
          onClick={() => handleCreateButton()}
          sx={{ width: '100px', height: '38px', borderRadius: 0, boxShadow: 0 }}
          textSx={{ fontSize: '14px' }}
        />
        <Button
          label={translate('notification.button.back')}
          onClick={() => handleBackButton()}
          sx={{ width: '100px', height: '38px', borderRadius: 0, boxShadow: 0 }}
          textSx={{ fontSize: '14px' }}
        />
      </Stack>
    </Layout>
  );
};
export default CreateNotificationScreen;
