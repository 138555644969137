export const MAIN_COLOR = '#3282DC';
export const MAIN_LIGHT_COLOR = '#639FDD';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const GRAY = '#6C757D';
export const SECOND_LIGHT_GRAY = '#80808F';
export const LIGHT_GRAY = '#F2F2F2';
export const LIGHT_PURPLE = '#BDC7F6';
export const TITLE = '#2E2C34';
export const TYPOGRAPHY = '#464E5F';
export const NOTICE = '#504F54';
export const GREEN = '#289B8F';
export const ORANGE = '#FD7E14';
export const POPUP_TITLE = 'rgba(0, 0, 0, 0.87)';
export const POPUP_CONTENT = 'rgba(0, 0, 0, 0.5)';
export const ARROW_COLOR = '#B5B5C3';
