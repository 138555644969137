/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { IMembersState } from './types';
import { IMemberLocal } from '../../libs/apis/members/types';

export const getMembers = createAsyncThunk(
  '/admin/members',
  async (memberData: IMemberLocal, { rejectWithValue }) => {
    try {
      const data: IMembersState =
        await apiSdk.memberApis.getMembers(memberData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
