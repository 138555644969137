/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotificationLocal } from '../../libs/apis/notifications/types';
import { INotificationsState } from './types';
import { apiSdk } from '../../libs/apis';

export const createNotification = createAsyncThunk(
  '/admin/notifications/create',
  async (notification: INotificationLocal, { rejectWithValue }) => {
    try {
      const data: INotificationsState =
        await apiSdk.notificationApis.createNotification(notification);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
