import { Button as MButton } from '@mui/material';
import { useState } from 'react';
import CreatePopup from './popup/CreatePopup';
import EditPopup from './popup/EditPopup';
import ErrorPopup from './popup/ErrorPopup';
import DeletePopup from './popup/DeletePopup';

interface Props {
  message: string;
}

const TestComponent = ({ message }: Props) => {
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  return (
    <>
      <h1>Get message from test api</h1>
      <h3>{message}</h3>
      <MButton variant="contained" onClick={() => setOpenCreateModal(true)}>
        Open Create Popup
      </MButton>
      <MButton variant="contained" onClick={() => setOpenEditModal(true)}>
        Open Edit Popup
      </MButton>
      <MButton variant="contained" onClick={() => setOpenErrorModal(true)}>
        Open Error Popup
      </MButton>
      <MButton variant="contained" onClick={() => setOpenDeleteModal(true)}>
        Open Delete Popup
      </MButton>
      <CreatePopup
        open={openCreateModal}
        onConfirm={() => setOpenCreateModal(false)}
        onClose={() => setOpenCreateModal(false)}
      />
      <EditPopup
        open={openEditModal}
        onConfirm={() => setOpenEditModal(false)}
        onClose={() => setOpenEditModal(false)}
      />
      <ErrorPopup
        open={openErrorModal}
        onConfirm={() => setOpenErrorModal(false)}
        onClose={() => setOpenErrorModal(false)}
      />
      <DeletePopup
        open={openDeleteModal}
        onConfirm={() => setOpenDeleteModal(false)}
        onCancel={() => setOpenDeleteModal(false)}
        onClose={() => setOpenDeleteModal(false)}
      />
    </>
  );
};

export default TestComponent;
