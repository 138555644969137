import { AxiosInstance } from 'axios';

import { IAuthLocal } from './types';
import { ILoginState, ILogoutState } from '../../../redux/auth/types';

export class AuthApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async login(user: IAuthLocal): Promise<ILoginState> {
    const { data } = await this.axiosInstance.post('/api/login', {
      email: user.email,
      password: user.password,
    });
    return data;
  }

  async logout(): Promise<ILogoutState> {
    const { data } = await this.axiosInstance.post('/api/logout');
    return data;
  }
}
