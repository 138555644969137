import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

export const formatDate = (date: string): string => {
  const parts = date.split('/');

  const createdAt = dayjs(
    new Date(
      Number.parseInt(parts[2]),
      Number.parseInt(parts[1]) - 1,
      Number.parseInt(parts[0]),
    ),
  );

  return createdAt.isToday() ? 'today' : createdAt.format('DD / MM');
};

export const getDate = (date?: string, format?: string): string => {
  return dayjs(date).format(format ?? 'YYYY/MM/DD') || '';
};

export const getTime = (date?: string): string => {
  return dayjs(date).format('HH:mm') || '';
};
