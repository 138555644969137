import React from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MAIN_COLOR, MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';

interface Props {
  label: string;
  onClick?: () => void;
}

const useStyles = makeStyles({
  button: {
    backgroundColor: MAIN_COLOR,
    color: WHITE,
    '&:hover': {
      backgroundColor: MAIN_LIGHT_COLOR,
    },
  },
});

const TableButton: React.FC<Props> = ({ label, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classes.button}
      sx={{
        height: '37px',
        borderRadius: '0px',
      }}
      variant="contained"
    >
      <Typography sx={{ fontSize: '14px' }}>{label}</Typography>
    </Button>
  );
};
export default TableButton;
