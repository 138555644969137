/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISeminarLocal } from '../../libs/apis/seminars/types';
import { ISeminarsState } from './types';
import { apiSdk } from '../../libs/apis';

export const getSeminars = createAsyncThunk(
  '/admin/seminars',
  async (seminarData: ISeminarLocal, { rejectWithValue }) => {
    try {
      const data: ISeminarsState =
        await apiSdk.seminarApis.getSeminars(seminarData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
