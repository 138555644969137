import { RootState } from '..';
import { IError } from './types';

export const successSelector = (state: RootState): boolean =>
  state.notification.success;

export const messageSelector = (state: RootState): string =>
  state.notification.message;

export const errorsSelector = (state: RootState): IError | undefined =>
  state.notification.errors;
