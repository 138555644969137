/* eslint-disable @typescript-eslint/no-explicit-any */
// import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS, TOKEN_KEYS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { login, logout } from './action';
import { ILoginState, ILogoutState } from './types';
import { setItemDataStorage } from '../../libs/utils/localStorage';

const loginInitState: ILoginState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
};

const logoutInitState: ILogoutState = {
  message: '',
  success: false,
  status: HTTP_STATUS.BAD_REQUEST,
};

export const initialState = {
  login: loginInitState,
  logout: logoutInitState,
};

const authSlice = createGenericSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.rejected, (state, action: any) => {
      state.login.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.login.message = action.payload.message;
        state.login.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.login.error = action.payload.errors;
      }
    });

    builder.addCase(login.fulfilled, (state, action: any) => {
      state.login.success = action.payload.success;
      state.login.data = action.payload.data;
      state.login.status = action.payload.status;
      setItemDataStorage(
        TOKEN_KEYS.ACCESS_TOKEN,
        action.payload.data.access_token,
      );
      setItemDataStorage(TOKEN_KEYS.EXPIRES_IN, action.payload.data.expires_in);
    });

    builder.addCase(logout.rejected, (state, action: any) => {
      state.logout.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.logout.message = action.payload.message;
        state.logout.success = action.payload.success;
      }
    });

    builder.addCase(logout.fulfilled, (state, action: any) => {
      state.logout.success = action.payload.success;
      state.logout.status = action.payload.status;
    });
  },
});

export { login, logout };
export default authSlice.reducer;
