import {
  ActionReducerMapBuilder,
  createSlice,
  Slice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

interface IParam<
  State,
  CaseReducers extends SliceCaseReducers<State> = SliceCaseReducers<State>,
> {
  name: string;
  initialState: State;
  reducers: ValidateSliceCaseReducers<State, CaseReducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<State>) => void;
}

export function createGenericSlice<
  State,
  CaseReducers extends SliceCaseReducers<State> = SliceCaseReducers<State>,
>({
  name,
  initialState,
  reducers,
  extraReducers,
}: IParam<State, CaseReducers>): Slice<State, CaseReducers, string> {
  return createSlice({
    name,
    initialState,
    reducers,
    extraReducers: (builder) => {
      extraReducers ? extraReducers(builder) : {};
    },
  });
}
