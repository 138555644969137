import { AxiosInstance } from 'axios';
import { IAdminUserState } from '../../../redux/admin-users/types';
import { IAdminUserLocal } from './types';

export class AdminUserApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getAdminUsers(adminUser: IAdminUserLocal): Promise<IAdminUserState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/admin-users?page=${adminUser.page}`,
    );
    return data;
  }
}
