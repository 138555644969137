import { useSelector } from 'react-redux';
import { testMessageSelector } from '../../redux/test/selectors';
import { useAppDispatch } from '../../redux';
import React, { useEffect } from 'react';
import { test } from '../../redux/test/action';
import TestComponent from '../../components/TestComponent';
import Layout from '../../components/Layout';

const TestView: React.FC = () => {
  const dispatch = useAppDispatch();
  const message = useSelector(testMessageSelector);
  useEffect(() => {
    dispatch(test());
  }, [dispatch]);

  return (
    <Layout title="">
      <div className="App">
        <TestComponent message={message} />
      </div>
    </Layout>
  );
};

export default TestView;
