import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  message: string;
}

const ErrorMessage: React.FC<Props> = ({ message }) => {
  return (
    <Typography
      sx={{
        fontSize: '12px',
        color: 'red',
        marginTop: '-10px',
        marginBottom: '-10px',
      }}
    >
      {message}
    </Typography>
  );
};

export default ErrorMessage;
