import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { POPUP_CONTENT } from '../../themes/colors';

interface Props {
  content: string;
}

const Content: FC<Props> = ({ content }: Props) => {
  return (
    <Typography
      sx={{
        fontSize: '12px',
        lineHeight: '18px',
        height: '15px',
        ml: '20px',
        color: POPUP_CONTENT,
      }}
    >
      {content}
    </Typography>
  );
};

export default Content;
