import { AxiosInstance } from 'axios';
import { IAreasState } from '../../../redux/areas/types';

export class AreaApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getAreas(): Promise<IAreasState> {
    const { data } = await this.axiosInstance.get(`/api/admin/areas`);
    return data;
  }
}
