/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { IAuthLocal } from '../../libs/apis/auth/types';
import { ILoginState, ILogoutState } from './types';

export const login = createAsyncThunk(
  '/login',
  async (user: IAuthLocal, { rejectWithValue }) => {
    try {
      const data: ILoginState = await apiSdk.authApis.login(user);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const logout = createAsyncThunk('/logout', async () => {
  const data: ILogoutState = await apiSdk.authApis.logout();
  return data;
});
