/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { getSeminars } from './action';
import { ISeminarsState } from './types';

export const initialState: ISeminarsState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
    seminars: [],
  },
};

const seminarSlice = createGenericSlice({
  name: 'seminar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSeminars.rejected, (state, action: any) => {
      state.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.message = action.payload.message;
        state.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.status = action.payload.status;
      }
    });

    builder.addCase(
      getSeminars.fulfilled,
      (state, action: PayloadAction<ISeminarsState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );
  },
});

export default seminarSlice.reducer;
