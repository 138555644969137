/* eslint-disable no-undef */
export type TKey = 'language';

export const setItemStorage = (key: TKey, value: string): void => {
  localStorage.setItem(key, value);
};

export const getItemStorage = (key: TKey): string | null => {
  return localStorage.getItem(key);
};

export const setItemDataStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const getItemDataStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const removeItemDataStorage = (key: string): void | null => {
  return localStorage.removeItem(key);
};
