import {
  Table as MTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';
import { MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';
import { IHeader } from '../../libs/utils';
import { TABLE_HEADER } from '../../themes/fonts';

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: MAIN_LIGHT_COLOR,
    height: '60px',
  },
});

interface Props {
  headers: IHeader[];
  children: ReactNode;
}

const Table: React.FC<Props> = ({ headers, children }) => {
  const styles = useStyles();
  return (
    <TableContainer>
      <MTable>
        <TableHead className={styles.tableHeader}>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                align="center"
                key={header.id}
                sx={{
                  color: WHITE,
                  fontWeight: 'bold',
                  fontSize: TABLE_HEADER,
                  minWidth: '55px',
                }}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </MTable>
    </TableContainer>
  );
};
export default Table;
