/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IHeader, translate } from '../../libs/utils';
import Pagination from '../../components/table/Pagination';
import TableBody from '../../components/participants/TableBody';
import Table from '../../components/table/Table';
import { useAppDispatch } from '../../redux';
import { useSelector } from 'react-redux';
import { seminarHaveParticipantsSelector } from '../../redux/participants/selectors';
import { getParticipants } from '../../redux/participants/action';
import { IData, ISeminars } from '../../redux/participants/types';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';

const headers: IHeader[] = [
  {
    id: 'title',
    label: translate('participant.list.table.seminar_name'),
  },
  {
    id: 'organizer_name',
    label: translate('participant.list.table.organizer'),
  },
  { id: 'target_area', label: translate('participant.list.table.target_area') },
  {
    id: 'apply_start_at',
    label: translate('participant.list.table.event_date'),
  },
  {
    id: 'apply_end_at',
    label: translate('participant.list.table.apply_end_at'),
  },
  {
    id: 'detail_action',
    label: translate('participant.list.table.detail_action'),
  },
];

const ParticipantsScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  // TODO: remove after integrate API
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedParticipantId, setSelectedParticipantId] = useState<number>(0);

  // TODO: uncomment after backend dev finish implementing get all participants api
  const dispatch = useAppDispatch();
  const data: IData = useSelector(seminarHaveParticipantsSelector);

  const handleOnChangePage = (page: number) => {
    dispatch(getParticipants({ page: page }));
    setCurrentPage(page);
  };

  const handleOnClickParticipantsDetail = (participantId: number) => {
    // TODO: dispatch api
    setSelectedParticipantId(participantId);
  };

  // TODO: uncomment after backend dev finish implementing get all participants api
  useEffect(() => {
    dispatch(getParticipants({ page: 1 }));
  }, [dispatch]);

  return (
    <Layout title={translate('participant.list.title')}>
      <Table headers={headers}>
        <TableBody
          data={data.seminars}
          onClickDetail={handleOnClickParticipantsDetail}
        />
      </Table>
      <Pagination
        total={calculateTotalPage(data.total)}
        page={currentPage}
        textTotal={`${translate('table.total.label')} ${data.total}${translate('participant.list.table.paginate.label')}`}
        onChangePage={handleOnChangePage}
      />
    </Layout>
  );
};
export default ParticipantsScreen;
