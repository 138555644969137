/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { getAreas } from './action';
import { IAreasState } from './types';

export const initialState: IAreasState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: [],
};

const areaSlice = createGenericSlice({
  name: 'area',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAreas.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.success = action.payload.success;
    });

    builder.addCase(
      getAreas.fulfilled,
      (state, action: PayloadAction<IAreasState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );
  },
});

export default areaSlice.reducer;
